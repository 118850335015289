@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", "sans-serif";
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}
